<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="update"
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="float: right"
        small
      >
        Update Availability Group
      </v-btn>
      <v-btn
        v-else
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="float: right"
      >
        Create Availability Group
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Availabilities</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form-new">
          <v-select
            v-model="states"
            :items="all_states"
            :menu-props="{ maxHeight: '400' }"
            label="States"
            multiple
            hint="Select States In This Region"
            persistent-hint
            required
          ></v-select>
          <v-text-field
            v-model="fee"
            label="Fees"
            required
            value="0"
          ></v-text-field>
          <v-text-field
            v-model="return_percentage"
            label="Return Percentage"
            required
            value="0"
          ></v-text-field>
          <v-text-field
            v-model="min_age"
            label="Min Age"
            required
            value="0"
          ></v-text-field>
          <v-text-field
            v-model="max_age"
            label="Max Age"
            required
            value="0"
          ></v-text-field>

          <v-select
            v-model="surrender_schedule_length"
            :items="[
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
            ]"
            label="Number of Surrender Years"
          >
          </v-select>
          <v-text-field
            v-for="index in surrender_schedule_length"
            :key="index"
            dense
            :label="'Surrender Value at Year ' + index"
            v-model="surrender_schedule[index - 1]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="dialog = false"> Cancel </v-btn>

        <v-btn color="primary" @click="create">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CreateAvailabilityGroup",
  props: [
    "product_id",
    "sa_id",
    "curr_states",
    "curr_surrender_schedule",
    "curr_fee",
    "curr_min_age",
    "curr_max_age",
    "curr_return_percentage",
  ],

  mounted() {
    if (this.curr_states) {
      // we assume they are all here
      this.update = true;
      this.states = this.curr_states;
      this.surrender_schedule = this.curr_surrender_schedule;
      this.surrender_schedule_length = this.curr_surrender_schedule.length;
      this.fee = this.curr_fee;

      this.max_age = this.curr_max_age;
      this.min_age = this.curr_min_age;
      this.return_percentage = this.curr_return_percentage;
    }
  },

  methods: {
    create() {
      this.surrender_schedule = this.surrender_schedule.map((val) => {
        return parseFloat(val);
      });

      if (this.update) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation(
                $state_availability_id: Int!
                $fee: numeric!
                $min_age: Int!
                $max_age: Int!
                $states: jsonb!
                $surrender_schedule: jsonb!
                $return_percentage: numeric!
              ) {
                update_state_availability(
                  where: { id: { _eq: $state_availability_id } }
                  _set: {
                    fee: $fee
                    states: $states
                    surrender_schedule: $surrender_schedule
                    min_age: $min_age
                    max_age: $max_age
                    return_percentage: $return_percentage
                  }
                ) {
                  returning {
                    id
                    fee
                    states
                    surrender_schedule
                    min_age
                    max_age
                    return_percentage
                  }
                }
              }
            `,
            variables: {
              state_availability_id: this.sa_id,
              fee: this.fee,
              min_age: this.min_age,
              max_age: this.max_age,
              states: this.states,
              surrender_schedule: this.surrender_schedule,
              return_percentage: this.return_percentage,
            },
          })
          .then(({ data }) => {
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.text = "Availability Group Updated";
            this.dialog = false;
            setTimeout(() => {
              this.$emit("create", data);
            }, 500);
          })
          .catch((error) => {
            console.log(error);
            alert("ERROR OCCURED");
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation(
                $fee: numeric!
                $product_id: Int!
                $min_age: Int!
                $max_age: Int!
                $states: jsonb!
                $surrender_schedule: jsonb!
                $return_percentage: numeric!
              ) {
                insert_state_availability_one(
                  object: {
                    fee: $fee
                    min_age: $min_age
                    max_age: $max_age
                    product_id: $product_id
                    states: $states
                    surrender_schedule: $surrender_schedule
                    return_percentage: $return_percentage
                  }
                ) {
                  id
                }
              }
            `,
            variables: {
              fee: this.fee,
              min_age: this.min_age,
              max_age: this.max_age,
              product_id: this.product_id,
              states: this.states,
              surrender_schedule: this.surrender_schedule,
              return_percentage: this.return_percentage,
            },
          })
          .then(({ data }) => {
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.text = "Availability Group Created";
            this.dialog = false;
            setTimeout(() => {
              this.$emit("create", data);
            }, 500);
          })
          .catch((error) => {
            console.log(error);
            alert("ERROR OCCURED");
          });
      }
    },
  },

  data: () => ({
    update: false,
    states: [],
    all_states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    fee: 0,
    max_age: 0,
    min_age: 0,
    surrender_schedule: [],
    surrender_schedule_length: 0,
    return_percentage: 0,
    dialog: false,
    rules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 3) || "Min 3 characters",
    ],
  }),
};
</script>
