var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.update)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v(" Update Availability Group ")]):_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Create Availability Group ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Availabilities")])]),_c('v-card-text',[_c('v-form',{ref:"form-new"},[_c('v-select',{attrs:{"items":_vm.all_states,"menu-props":{ maxHeight: '400' },"label":"States","multiple":"","hint":"Select States In This Region","persistent-hint":"","required":""},model:{value:(_vm.states),callback:function ($$v) {_vm.states=$$v},expression:"states"}}),_c('v-text-field',{attrs:{"label":"Fees","required":"","value":"0"},model:{value:(_vm.fee),callback:function ($$v) {_vm.fee=$$v},expression:"fee"}}),_c('v-text-field',{attrs:{"label":"Return Percentage","required":"","value":"0"},model:{value:(_vm.return_percentage),callback:function ($$v) {_vm.return_percentage=$$v},expression:"return_percentage"}}),_c('v-text-field',{attrs:{"label":"Min Age","required":"","value":"0"},model:{value:(_vm.min_age),callback:function ($$v) {_vm.min_age=$$v},expression:"min_age"}}),_c('v-text-field',{attrs:{"label":"Max Age","required":"","value":"0"},model:{value:(_vm.max_age),callback:function ($$v) {_vm.max_age=$$v},expression:"max_age"}}),_c('v-select',{attrs:{"items":[
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20 ],"label":"Number of Surrender Years"},model:{value:(_vm.surrender_schedule_length),callback:function ($$v) {_vm.surrender_schedule_length=$$v},expression:"surrender_schedule_length"}}),_vm._l((_vm.surrender_schedule_length),function(index){return _c('v-text-field',{key:index,attrs:{"dense":"","label":'Surrender Value at Year ' + index},model:{value:(_vm.surrender_schedule[index - 1]),callback:function ($$v) {_vm.$set(_vm.surrender_schedule, index - 1, $$v)},expression:"surrender_schedule[index - 1]"}})})],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }