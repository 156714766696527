<template>
  <v-data-table
    :headers="headers"
    :items="product_data"
    sort-by="name"
    class="elevation-3"
    @click:row="handleClick"
    disable-pagination
    :hide-default-footer="true"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Products</v-toolbar-title>
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Product
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click.stop="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click.stop="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import gql from "graphql-tag";
import dayjs from "dayjs";

export default {
  name: "ProductTable",
  props: ["carrier_id", "product_data", "loading"],

  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Product Name",
        value: "name",
      },
      { text: "Availability Groups", value: "sa_cnt" },
      { text: "Last Updated", value: "updated" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      sa_cnt: 0,
      name: null,
    },
    defaultItem: {
      id: 0,
      sa_cnt: 0,
      name: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },

  watch: {
    loading: function(val) {
      if (!val) this.initialize();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    initialize() {
      this.product_data.forEach((item) => {
        console.log(item);
        item.updated = dayjs(item.updated_at).format("MM/DD/YYYY");
        item.sa_cnt = item.state_availabilities_aggregate.aggregate.count;
      });
    },
    handleClick: function(e) {
      this.$router.push(`/product/${e.id}`);
    },
    editItem(item) {
      this.editedIndex = this.product_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.product_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.product_data.splice(this.editedIndex, 1);

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: Int!) {
              delete_products_by_pk(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: this.editedItem.id,
          },
        })
        .then(({ data }) => {
          console.log("result", data);
          this.$store.state.snackbar.show = true;
          this.$store.state.snackbar.text = "Product Deleted";
        })
        .catch((error) => {
          console.log(error);
          alert("ERROR OCCURED");
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // update
        console.log(this.editedItem);

        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateProduct($id: Int!, $name: String!) {
                update_products_by_pk(
                  pk_columns: { id: $id }
                  _set: { name: $name }
                ) {
                  id
                }
              }
            `,
            variables: {
              id: this.editedItem.id,
              name: this.editedItem.name,
            },
          })
          .then(({ data }) => {
            console.log(data);
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.text = "Product Updated";
          })
          .catch((error) => {
            console.log(error);
            alert("ERROR OCCURED");
          });

        this.editedItem.updated = dayjs().format("MM/DD/YYYY");

        Object.assign(this.product_data[this.editedIndex], this.editedItem);
      } else {
        // create
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($carrier_id: Int!, $name: String) {
                insert_products_one(
                  object: { carrier_id: $carrier_id, name: $name }
                ) {
                  id
                  name
                  updated_at
                }
              }
            `,
            variables: {
              carrier_id: this.carrier_id,
              name: this.editedItem.name,
            },
          })
          .then(({ data }) => {
            data.insert_products_one.sa_cnt = 0;
            data.insert_products_one.updated = dayjs().format("MM/DD/YYYY");
            console.log(data);
            this.product_data.push(data.insert_products_one);
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.text = "Product created";
          })
          .catch((error) => {
            console.log(error);
            alert("ERROR OCCURED");
          });
      }
      this.close();
    },
  },
};
</script>
