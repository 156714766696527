<template>
  <v-container>
    <h2>
      <router-link to="/">Carriers</router-link>
      <span> / </span>
      {{ carrier.name }}
    </h2>
    <br />

    <product-table
      :carrier_id="$route.params.id"
      :product_data="this.products"
      :loading="this.loading"
    />
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import ProductTable from "../components/ProductTable.vue";

export default {
  name: "Carrier",
  mounted() {
    this.loadCarrier();
  },

  methods: {
    async loadCarrier() {
      this.loading = true;
      let carrier_id = this.$route.params.id;

      const { data } = await this.$apollo.query({
        query: gql`
          query($cid: Int) {
            products(where: { carrier_id: { _eq: $cid } }) {
              id
              name
              updated_at
              carrier {
                id
                name
              }
              state_availabilities_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        `,
        variables: {
          cid: carrier_id,
        },
      });

      this.products = data.products;
      if (this.products.length > 0) {
        this.carrier = data.products[0].carrier;
      } else {
        const { data } = await this.$apollo.query({
          query: gql`
            query($cid: Int!) {
              carriers_by_pk(id: $cid) {
                id
                name
              }
            }
          `,
          variables: {
            cid: carrier_id,
          },
        });
        this.carrier = data.carriers_by_pk;
      }
      this.loading = false;
    },
    handleClick: function(e) {
      this.$router.push(`/product/${e.id}`);
    },
  },
  data() {
    return {
      products: [],
      loading: true,
      carrier_id: null,
      carrier: {
        name: "Loading...",
      },
      dialog: false,
      headers: [
        {
          text: "Product Name",
          value: "name",
        },
        {
          text: "Availability Groups",
          value: "availability_groups",
        },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  components: {
    // HelloWorld,
    ProductTable,
  },
};
</script>
