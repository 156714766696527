<template>
  <v-container>
    <h2>
      <router-link to="/">Carriers</router-link>
      <span> / </span>
      <router-link :to="'/carrier/' + carrier.id">{{
        carrier.name
      }}</router-link>
      <span> / </span>
      {{ product.name }}
      <create-state-availabilities
        :product_id="$route.params.id"
        v-on:create="loadProduct(true)"
      />
    </h2>
    <!-- <create-product :carrier_id="$route.params.id" /> -->
    <br />

    <div v-if="this.loading">Loading...</div>
    <div v-else>
      <v-card
        v-for="sa in state_availabilities"
        :key="sa.id"
        style="margin-bottom: 20px"
      >
        <v-card-title>
          Availability Group
          <v-spacer></v-spacer>

          <create-state-availabilities
            :product_id="$route.params.id"
            v-on:create="loadProduct(true)"
            :sa_id="sa.id"
            :curr_states="sa.states"
            :curr_surrender_schedule="sa.surrender_schedule"
            :curr_fee="sa.fee"
            :curr_min_age="sa.min_age"
            :curr_max_age="sa.max_age"
            :curr_return_percentage="sa.return_percentage"
          />
        </v-card-title>
        <v-card-text>
          <span class="bold">Last Updated: </span>
          {{ formatDate(sa.updated_at) }}
          <br />
          <span class="bold">States: </span>
          <span v-for="(state, index) in sa.states" v-bind:key="state"
            >{{ state }}{{ index == sa.states.length - 1 ? "" : "," }}
          </span>
          <br />
          <span class="bold">Fees:</span> {{ sa.fee }}%
          <br />
          <span class="bold">Min Age:</span> {{ sa.min_age }}
          <br />
          <span class="bold">Max Age:</span> {{ sa.max_age }}
          <br />
          <span class="bold">Return Percentage:</span>
          {{ sa.return_percentage || "Unknown " }}%

          <br />
          <span class="bold">Surrender Schedule: </span>
          <span
            v-for="(surrender, index) in sa.surrender_schedule"
            v-bind:key="index"
            >{{ surrender }}%{{
              index == sa.surrender_schedule.length - 1 ? "" : ","
            }}
          </span>
          <br />
          <br />
          <!-- Bonus Table Here -->
          <bonus-table :sa_id="sa.id" :bonus_data="sa.bonuses" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>
.v-card {
  margin-bottom: 20px !important;
}

.bold {
  font-weight: bold;
}
</style>

<script>
import gql from "graphql-tag";
import dayjs from "dayjs";
import CreateStateAvailabilities from "../components/CreateStateAvailabilities.vue";
import BonusTable from "../components/BonusTable.vue";

export default {
  name: "ProductDetail",
  mounted() {
    this.loadProduct(false);
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format("MM/DD/YYYY");
    },

    async loadProduct(reload) {
      let product_id = this.$route.params.id;
      this.loading = true;

      let fetchPolicy = "cache-first";
      if (reload) {
        fetchPolicy = "network-only";
      }

      const { data } = await this.$apollo.query({
        fetchPolicy: fetchPolicy,
        query: gql`
          query($pid: Int!) {
            products_by_pk(id: $pid) {
              carrier {
                id
                name
              }
              name
              state_availabilities(order_by: { id: asc }) {
                id
                fee
                states
                surrender_schedule
                min_age
                max_age
                updated_at
                return_percentage
                bonuses {
                  id
                  bonus_amount
                  max_age
                  min_age
                }
              }
            }
          }
        `,
        variables: {
          pid: product_id,
        },
      });

      this.product = data.products_by_pk;
      this.carrier = data.products_by_pk.carrier;
      this.state_availabilities = data.products_by_pk.state_availabilities;
      this.loading = false;
    },
  },
  data() {
    return {
      loading: true,
      carrier: {
        name: "Loading...",
      },
      product: {
        name: "",
      },
      state_availabilities: [],
      dialog: false,
    };
  },
  components: {
    CreateStateAvailabilities,
    BonusTable,
  },
};
</script>
